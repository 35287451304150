.about {
  margin: auto;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
}

.about .container {
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about button {
  background-color: #eee;
  color: black;
  margin-top: 1rem;
  border-radius: 99px;
}

@media screen and (max-width: 940px) {
  .about .container {
    grid-template-columns: 1fr;
  }

  .about .container {
    width: 100%;
  }

  .about .container img {
    padding: 0rem;
    width: 90vw;
  }

  .about .container .col-2 {
    padding: 0;
  }

  .about button {
    margin-bottom: 1rem;
    border-radius: 99px;
  }
}
