.embla1 {
  max-width: 80%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 2rem;
  --slide-size: 100%;
}
.embla__viewport1 {
  overflow: hidden;
}
.embla__container1 {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide1 {
  display: flex;
  flex: 0 0 var(--slide-size);
  align-items: center;
  min-width: 0;
  padding-left: var(--slide-spacing);
  align-content: center;
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__dots {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(49, 49, 49);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.2rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem rgb(255, 255, 255);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem rgb(255, 3, 3);
}

@media screen and (max-width: 940px) {
  .embla__dot {
    -webkit-tap-highlight-color: rgba(49, 49, 49);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
