.program_container {
  display: flex;
  justify-content: center;
}
.header.cek {
  width: 50px;
  display: flex;
  justify-content: center;
}

.leaderboard {
  padding: 20px;
  max-width: 490px;
  width: 100%;
  border-radius: 12px;
}
.leaderboard header {
  --start: 15%;
  height: 130px;
  background-color: #eee;
  color: black;
  position: relative;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.leaderboard header .leaderboard__title {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: calc(var(--start) * 0.75);
  transform: translateY(-50%);
  text-transform: uppercase;
  margin: 0;
}
.leaderboard header .leaderboard__title span {
  display: block;
}
.leaderboard header .leaderboard__title--top {
  font-size: 30px;
  font-weight: 700;
}
.leaderboard header .leaderboard__title--bottom {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3.55px;
  transform: translateY(-2px);
}
.leaderboard header .leaderboard__icon {
  max-width: 280px;
  padding: 100px;
  position: relative;
  top: 50%;
  left: var(--start);
  transform: translate(-49%, -28%);
}
.leaderboard__profiles {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  padding: 15px 15px 20px;
  display: grid;
  row-gap: 8px;
}
.leaderboard__profile {
  display: grid;
  grid-template-columns: 1fr 2fr 0fr;
  align-items: center;
  padding: 10px 30px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
    box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
}
.leaderboard__profile:hover {
  transform: scale(1.1);
  box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}
.leaderboard__picture {
  max-width: 100%;
  width: 60px;
  border-radius: 50%;
  box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
}
.leaderboard__name {
  color: #000000;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.64px;
  margin-left: 12px;
}

.leaderboard {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 940px) {
  .leaderboard {
    padding: 30px;
  }
}

@media screen and (max-width: 940px) {
  .leaderboard__title {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: calc(var(--start) * 0.75);
    transform: translateY(-50%);
    text-transform: uppercase;
    margin: 0;
  }
}

@media screen and (max-width: 940px) {
  .leaderboard__icon {
    max-width: 200px;
  }
}
