.hero {
  background-color: rgba(99, 65, 65, 0.45);
  color: #fff;
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero:before {
  content: "";
  background: url("./images/hero.jpg") no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
  align-items: flex-end;
}

.hero .content p:first-child {
  font-style: bold;
  font-size: 5rem;
}

.hero .content p:nth-child(2) {
  font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
  font-size: 5rem;
  font-weight: 700;
}

.hero .content p:nth-child(4) {
  font-size: 2.2rem;
  font-style: italic;
}

.hero .button {
  margin: 1rem 0;
  width: 300px;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 99px;

  color: #333;
}

.hero .button:hover {
  background-color: var(--secondary-dark);
  border: 1px solid var(--secondary-dark);
}

@media screen and (max-width: 940px) {
  .hero .content p:nth-child(3) {
    font-size: 2.6rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.6rem;
  }
}
