.footer {
  width: 100%;
  background-color: #aa2a2ae6;
  color: #eee;
  padding: 1px 1px;
  justify-items: center;
}

.footer .container {
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: grid;
  justify-items: center;
  align-items: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer li {
  padding: 0.5rem;
}

.footer li a {
  color: #fff;
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  text-align: center;
  padding-top: 1px;
}

.line {
  margin-bottom: 1px;
}
a {
  text-decoration: none;
}
.btn-floating {
  position: fixed;
  right: 25px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 0;
  z-index: 9999;
  color: white;
  transition: 0.2s;
}

.btn-floating:hover {
  width: auto;
  height: 60px;
  padding: 0 20px;
  cursor: pointer;
}

.btn-floating span {
  font-size: 16px;
  margin-left: 5px;
  transition: 0.2s;
  line-height: 0px;
  display: none;
}

.btn-floating:hover span {
  display: inline-block;
}

.btn-floating:hover img {
  margin-bottom: -3px;
}

.btn-floating.whatsapp {
  bottom: 25px;
  background-color: #34af23;
  border: 2px solid #fff;
}

.btn-floating.whatsapp:hover {
  background-color: #1f7a12;
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}

.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}

.svg-inline--fa {
  vertical-align: -0.2em;
}

.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-tiktok,
.fa-youtube,
.fa-instagram {
  font-size: 25px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover,
.rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.tiktok {
  background: #000000;
}

.rounded-social-buttons .social-button.tiktok:hover,
.rounded-social-buttons .social-button.tiktok:focus {
  color: #000000;
  background: #fefefe;
  border-color: #000000;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  background: #125688;
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}
