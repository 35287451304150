.embla {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 32rem;
  padding: 20px;
}
.embla__viewport {
  overflow: hidden;
  height: 25rem;
  border-width: 1px;
  border: none;
}
.embla__container {
  display: flex;
  height: 100%;
}
.embla__slide {
  flex: 0 0 50%;
  min-width: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_container_embla {
  display: flex;
  justify-content: space-around;
}

.button_embla {
  background-color: #eee;
  border-radius: 99px;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.7em 1.7em;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 4px 2px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.button_embla:hover {
  opacity: 1;
}
