.div1 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: 80rem;
}

@media screen and (max-width: 940px) {
  .div1 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .div1 {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.div2 {
  max-width: 100%;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .div2 {
    max-width: 100%;
  }
}

.div3 {
  font-weight: 600;
  border-radius: 999px;
  background-color: #eee;
  color: black;
  font-weight: 700;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  padding-top: 10px;
  padding-bottom: 10px;
}
.div4 {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: white;
}
.div5 {
  margin-top: 4rem;
}

@media screen and (min-width: 1024px) {
  .div5 {
    margin-top: 5rem;
  }
}

.div6 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  .div6 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.div7 {
  overflow: hidden;
  border-radius: 0.5rem;
}

.div8 {
  overflow: hidden;
  border-radius: 0.5rem;
  max-width: 100%;
}
.div9 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-top-width: 1px;
  border-color: #e5e7eb;
}
.div10 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: white;
}
.div11 {
  margin-top: 0.25rem;
  color: white;
}
